<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="优惠券信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>

        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 优惠券编号</template>
            {{ useInfoData.couponInfo.couponCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 优惠券类型</template>
            {{
              useInfoData.couponInfo.ruleType === 0
                  ? "面额券"
                  : useInfoData.couponInfo.ruleType === 1
                      ? "免费时长券"
                      : "免费停车券"
            }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 优惠额度</template>
            {{ (parseFloat(useInfoData.couponInfo.faceAmount)).toFixed(2) }}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 领取账户</template>
            <span
                v-if="useInfoData.couponInfo.receiveAccount.useAccount === ''"
            >
              <Numplate :type="useInfoData.couponInfo.receiveAccount.autoType">{{
                  useInfoData.couponInfo.receiveAccount.plateNumber
                }}</Numplate>
            </span>
            <span
                v-if="useInfoData.couponInfo.receiveAccount.useAccount !== ''"
            ></span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 领取时间</template>
            {{ useInfoData.couponInfo.receiveDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 发券操作人</template>
            {{ useInfoData.couponInfo.operator === 0 ? "用户" : useInfoData.couponInfo.operator === 1 ? "商户" : "平台" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 操作原因</template>
            {{ useInfoData.couponInfo.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 状态</template>
            {{
              useInfoData.couponInfo.status === 1
                  ? "未使用"
                  : useInfoData.couponInfo.status === 2
                      ? "已使用"
                      : "已过期"
            }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card title="事件规则">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 发行编号</template>
            {{ useInfoData.event.couponRuleCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 标题</template>
            {{ useInfoData.event.title }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 触发事件</template>
            {{ useInfoData.event.eventName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 承担方</template>
            {{ useInfoData.event.bearerName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 适用业务类型</template>
            {{ useInfoData.event.couponType === 0 ? "通用" : "停车券" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方</template>
            {{ useInfoData.event.paySettleAccountName }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card title="关联订单">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 订单编号</template>
            {{ useInfoData.order.parkingOrderId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用账户</template>
            {{ useInfoData.order.useUser }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用车辆</template>
            <Numplate :type="useInfoData.order.useVehicle.autoType">{{
                useInfoData.order.useVehicle.plateNumber
              }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 抵扣额</template>
            {{ (parseFloat(useInfoData.order.discountAmount)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用区域</template>
            {{ useInfoData.order.useRegionName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用车场</template>
            {{ useInfoData.order.useParkingLotName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶入时间</template>
            {{ useInfoData.order.driveInDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶出时间</template>
            {{ useInfoData.order.driveOutDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            {{ (parseFloat(useInfoData.order.orderMoney)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 应收金额</template>
            <span
                v-if="useInfoData.order.amountReceivable !== ''">{{ (parseFloat(useInfoData.order.amountReceivable)).toFixed(2) }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 交易金额</template>
            {{ (parseFloat(useInfoData.order.actualAmount)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用时间</template>
            {{ useInfoData.order.useDate }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
    </div>
  </div>
</template>


<script>
import {getCouponUseInfo} from "@/api/coupon";
import Numplate from "@/components/Numplate";

export default {
  data() {
    return {
      useInfoData: {
        couponInfo: {},
        event: {},
        order: {},
      },
    };
  },
  components: {
    Numplate,
  },
  methods: {
    //返回停车订单页面
    goBack() {
      this.$router.back();
    },
    //根据id查询使用详情
    getCouponUseInfo(id) {
      getCouponUseInfo(id).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.useInfoData = res.returnObject;
          console.log(this.useInfoData);
        }
      });
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.getCouponUseInfo(id);
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}
</style>
